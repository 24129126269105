import React, {useState, useEffect, useContext} from 'react';
import { courseDetailsList, downloadCourseHeaders } from '../../ProData';
import { Table, Breadcrumb, Button, ButtonGroup} from 'react-bootstrap';
import Preloader from '../../components/Preloader/Preloader';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import { ProductContext } from '../../ProductProvider';
import {Helmet} from 'react-helmet-async';

export default function CourseView() {
  const { loggedUser, validateLogin } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(true);
  const componentRef = React.useRef(null);
  const csvLinkEl = React.createRef();

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      //processBatchYear();
       // handleSearch();
        //console.log("Logged Use : ", loggedUser);
        setLoaded(true);
    }else{
        validateLogin();
    }
    //setLoaded(true);
   // console.log(batchYear);
  },[loggedUser])


  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }
  

  return (
    <div>
       <Helmet>
      <title>Courses - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Courses - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Courses - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/course" />
    </Helmet>
   
    <Preloader show={loaded ? false : true} />
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Students
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Course
    </Breadcrumb.Item>
    </Breadcrumb>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">  
    <h4>Course Details</h4>
    </div>
    <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="course"
      trigger={reactToPrintTrigger}
    />
        <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadCourseHeaders}
            filename="download.csv"
            data={courseDetailsList}
            ref={csvLinkEl}
          />
        </ButtonGroup>
      </div>
    </div>
<Table ref={componentRef} hover responsive >
        <thead className='theadStyle'>
            <tr>
                <th>#</th>
                <th>Code</th>
                <th>Name</th>
                <th>Credit</th>
                <th>Category</th>
                <th>Year</th>
            </tr>
        </thead>
        <tbody className='tbodyStyle'>
        {courseDetailsList.map((item, index)=>
        <tr key={index} className='trStyle'>
        <th scope="row">{index+1}</th>
        <td>{item.courseCode}</td>
        <td>{item.courseName}</td>
        <td>{item.courseCredit}</td>
        <td>{item.courseType}</td>
        <td>{item.courseYear}</td>
        </tr>)}
        </tbody>
    </Table>
    </div>  )
}
