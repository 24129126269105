import React, { useState } from "react";
import { Form, Row, Col} from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEye, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader/Preloader";
import '../../styles.css';
import Resizer from "react-image-file-resizer";

export default function AddGallery(props) {
    const {setNewProjectName, newProjectName, setNewProjectDate, newProjectDate, setImageFile, profileImage}=props;
    const inputRef = React.useRef();
    const [loaded, setLoaded] = useState(true);
   
    const handleFileSelection = async (event)=>{
      setLoaded(false);
        event.preventDefault();
        if (event.target.files && event.target.files.length > 0) {

          try {
            await Resizer.imageFileResizer(
              event.target.files[0],
              1500,
              1500,
              "JPEG",
              100,
              0,
              (uri) => {
                //console.log(uri);
                setImageFile(uri);
                //console.log("compressed");
               // this.setState({ newImage: uri });
              },
              "blob",
              400,
              400
            );
          } catch (err) {
            console.log(err);
          }
       
          //setImageFile(event.target.files[0]);
          //console.log("selected file : ", event.target.files[0].name);
          var tprofile = document.getElementById("img1");
          tprofile.src=URL.createObjectURL(event.target.files[0]);
          /*setAlertDetails({
           ...alertDetails,
           style:"info",
           open:true,
           message:"File Selected - "+ event.target.files[0].name
       }); */  
      }
      setLoaded(true);
    }

  return (
    <>
    <Preloader show={loaded ? false : true} />
     <div>
     <div className="text-center">
          <img id="img1" alt="Selected Image" src={profileImage}
          style={{ height: 180, width: 180 }} />
        </div>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextFile">
          <Form.Label column sm="4">
            Gallery Name
          </Form.Label>
          <Col sm="8">
            <Form.Control type="text" placeholder="Enter Gallery Name" 
            controlId="formGalleryName"
            onChange={event=>setNewProjectName(event.target.value)}
            value={newProjectName} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextDate">
          <Form.Label column sm="4">Date</Form.Label>
          <Col sm="8">
            <Form.Control type="date" placeholder="Enter Gallery Date" 
            controlId="formGalleryDate"
            onChange={event=>setNewProjectDate(event.target.value)}
            value={newProjectDate} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextImage">
          <Form.Label column sm="4">
            Select Image
          </Form.Label>
          <Col sm="8">
          <Form.Control type="file"
            controlId="formFileImage"  
            accept="image/*" ref={inputRef}
            onChange={handleFileSelection} />
          </Col>
        </Form.Group>
    </div>
    </>

    )
}
