import React,{useEffect, useContext, useState} from 'react';
import logo from '../assets/images/logo/COLLEGELOGO.jpeg';
import { ProductContext } from '../ProductProvider';
import Preloader from '../components/Preloader/Preloader';
import {Helmet} from 'react-helmet-async';

export default function LandingPage() {
  const { validateLogin, loggedUser} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if(!loggedUser.isAuthenticated){
      validateLogin();
    }
    setLoaded(true);
    //console.log("logged user : ", loggedUser);
  }, [loggedUser])

  return (
    <>
     <Helmet>
      <title>Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/" />
    </Helmet>
   
          <Preloader show={loaded ? false : true} />
    <div style={{marginTop:'5%', marginBottom:'auto'}}>
      <div className="text-center">
        <div className="dashboard-sec">
          <div className="">
    <h1 style={{align:"center"}}>Welcome to Vidyajyoti College</h1>
        <img src={logo}  className="dash-logo shadow-light rounded-circle"/>
    <h1 align="center" variant="h1">Student Portal</h1>
    </div>
    </div>
    </div>
    </div>
    </>
  )
}