import React, {useContext, useEffect} from 'react';
import { Table,Button, ButtonGroup } from 'react-bootstrap';
//import Preloader from '../../components/Preloader/Preloader';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome } from '@fortawesome/free-solid-svg-icons';
import { ProductContext } from '../../ProductProvider';
//import { Link } from "react-router-dom";
//import axios from 'axios';
//import { fetchAuthSession } from 'aws-amplify/auth';
//import { useSnackbar } from 'notistack';
import { CSVLink } from "react-csv";
import ReactToPrint from "react-to-print";
import { downloadMarkSheetHeaders } from '../../ProData';

//const config = require('../../config.json');

export default function MarkSheetView(props) {
  const { loggedUser, validateLogin } = useContext(ProductContext);
  const {searchResults}= props;
  //const [searchResults, setSearchResults] = useState([]);
  //const [loaded, setLoaded] = useState(true);
  const componentRef = React.useRef(null);
  const csvLinkEl = React.createRef();

  //const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      //processBatchYear();
     // handleSearch();
     //console.log("Logged Use : ", loggedUser);
    }else{
        validateLogin();
    }
   // console.log(batchYear);
  },[loggedUser])

  /*const handleSearch=async()=>{
    //event.preventDefault();
    try{
        setLoaded(false);
        const { idToken } = (await fetchAuthSession()).tokens ?? {};
        const searchOption="RegistrationID";
        const searchValue=loggedUser.nickname;
        //console.log("searchvlaue : ", searchValue);
      await axios.get(`${config.api.invokeURL}/dashboard/student/marksheet?searchValue=${searchValue}&searchOption=${searchOption}`, {
        headers: {
          Authorization: idToken,
          'x-api-key':config.api.key
        }
      }).then(async(response) => {
       //console.log("response : ", response.data);
        if(response.data.length>0)
        {
            setSearchResults(response.data);
        }
        enqueueSnackbar("Marksheet loaded successfully",{variant:'success'}); 
        setLoaded(true);
    }).catch((error)=>{
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
  })
    }catch(error){
      enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    setLoaded(true);
    }
  }*/

  const handleDownload =async(event)=>{
    event.preventDefault();
    csvLinkEl.current.link.click();
  }

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return <Button variant="outline-primary"  size="sm">Print</Button>;
  }, []);



  return (
    <div>
    {/*<Preloader show={loaded ? false : true} />
    <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <FontAwesomeIcon icon={faHome} />
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Students
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Mark Sheet
    </Breadcrumb.Item>
  </Breadcrumb>*/}
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      <div className="d-block mb-4 mb-md-0">  
    <h4>Marksheet Details</h4>
    </div>
    <div className="btn-toolbar mb-2 mb-md-0"> 
        <ButtonGroup>
        <ReactToPrint
      content={reactToPrintContent}
      documentTitle="marksheet"
      trigger={reactToPrintTrigger}
    />
        <Button variant="outline-primary" size="sm" onClick={(event)=>{handleDownload(event)}}>Download</Button>
        <CSVLink
            headers={downloadMarkSheetHeaders}
            filename="download.csv"
            data={searchResults}
            ref={csvLinkEl}
          />
        </ButtonGroup>
      </div>
    </div>
    <Table ref={componentRef} hover responsive>
        <thead className='theadStyle'>
            <tr>
                <th>#</th>
                <th>Code</th>
                <th>Name</th>
                <th>Credit</th>
                <th>Marks</th>
            </tr>
        </thead>
        <tbody className='tbodyStyle'>
        {searchResults[1].map((item, index)=>
        <tr key={index} className='trStyle'>
        <th scope="row">{index+1}</th>
        <td>{item.courseCode}</td>
        <td>{item.courseName}</td>
        <td>{item.courseCredit}</td>
        <td>{item.courseMarks}</td>
        </tr>)}
        </tbody>
    </Table>
    </div>
  )
}
