import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
import { Button, Dropdown, ButtonGroup, Breadcrumb } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faPlus, faEye, faEdit, faTrash, faEllipsisH, faHome } from '@fortawesome/free-solid-svg-icons';
import {Modal, Table  } from "react-bootstrap";
import { useSnackbar } from 'notistack';
import {Helmet} from 'react-helmet-async';
import { ProductContext } from '../../../ProductProvider';
import InfoDeskForm from './InfoDeskForm';
import Preloader from '../../../components/Preloader/Preloader';
const config = require('../../../config.json');

export default function ActiveInfo() {
    const { validateLogin, loggedUser} = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [loaded, setLoaded] = useState(false);
    const [newCollapsed, setNewCollapsed] = useState(true);
    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState('vjdepth-logo.png');
    const [newProjectName, setNewProjectName]=useState('');
    const [infoDesk, setInfoDesk]=useState({startDate:'', endDate:'',msgCategory:'Flash News', msgStatus:'Active'});
    const [flashNews, setFlashNews]=useState('');
    const [scrollMsg, setScrollMsg]=useState({title:'', email:'', link:'', message:'',image:'vjdepth-logo.png'});
    const [faithMsg, setFaithMsg]=useState({title:'', head:'', message:''});
    const [activePost, setActivePost]=useState([]);
    const [formType, setFormType]=useState("View");
    const [selectedItem, setSelectedItem]=useState('');

    useEffect(() => {
       if(loggedUser.isAuthenticated && loggedUser.profile==="Super Student"){
            fetchActiveInfo();
        }else{
            validateLogin();
        }       
    }, [loggedUser]);

    /*const postOnS3 = async()=>{
        try{
            const fileName="Gallery_"+new Date().getTime();
          const newFileName=fileName+".jpeg";
          const bucketName=config.s3.bucketName+"/gallery";
          //console.log("bucket name : ", bucketName);
          var file = imageFile;
          var blob = file.slice(0, file.size, 'image/jpeg'); 
          const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
          //console.log("New file : ", newFile);
          await axios.get(`${config.api.invokeUrl}/images/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
               headers:{
                   //Authorization: access_token,
                  // 'x-api-key': config.api.key
               }
           }).then(async(response)=>{
            //   console.log("Response : ", response);
              const res=await fetch(response.data.uploadURL, { method: 'PUT',
               headers:{
                   "Content-Type":"image/jpeg"
                 },body: newFile});
                // console.log("Res : ", res);
                 if(res.status===200){
                    fetchGalleryImages();
                     setProfileImage(newFileName);
                     //var timestamp = new Date().getTime();     
                     var tprofile = document.getElementById("profile");
                     //var imgsrc="";
                     //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                     //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                    // tprofile.src=imgsrc;
                    tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                     //console.log("tprofle : ", tprofile.src);
    
                 }
            }).catch((error)=>{
                console.log("error1:", error);
             // setDispMsg(error.message);
             // hideLoader();
           })
        }catch(error){
          console.log("error1:", error);
        }
    
      }*/

    /*const toggle = () => {
           //console.log("clicked");
             setCollapsed(!collapsed);
    }
*/
    const newToggle = () => {
    console.log("clicked");
        setNewCollapsed(!newCollapsed);
    }

    /*const editToggle = () => {
        //console.log("clicked");
            setEditCollapsed(!editCollapsed);
    }*/
    const generateDate = async () => {
        const current_datetime = new Date();
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        var date_hours = (current_datetime.getHours().toString());
        var hours_value="";
        date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
        var date_minutes = (current_datetime.getMinutes().toString());
        var minutes_value="";
        date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
        var todayDate="";
        const tDate = todayDate.concat(date_year,"-",month_value,"-", date_value);
        //setUserId(accountNo);
        return tDate;
        //console.log("acc : ", accountNo);
    }    

    const fetchActiveInfo =async()=>{
        try{
            setLoaded(false);
           //console.log("Testing");
           //const session = await Auth.currentSession();
           //const access_token=session.idToken.jwtToken;
           const { idToken } = (await fetchAuthSession()).tokens ?? {};
           //const test = await fetchAuthSession();
            const startDate=await generateDate();
            //console.log("start Date : ", startDate);
            const clientID="VJCollege";
            const searchOption="All";
        await axios.get(`${config.api.invokeURL}/dashboard/infodesk?clientID=${clientID}&startDate=${startDate}&searchOption=${searchOption}`,{
            headers: {
              Authorization: idToken,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
            //console.log(response);
            setActivePost(response.data);
           // setGalleryImages(response.data);
           enqueueSnackbar(response.data.length + " - Active Post available", {variant:'success'}); 
            setLoaded(true);
            //setDispMsg(response.data.length + " - Images are found in your Gallery");
        }).catch((error)=>{
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
            setLoaded(true);
        })
    }catch(error){
		//console.log(error);
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
        //setDispMsg(error.message);
    }
    }

    const handleProjectView=(event, item)=>{
        event.preventDefault();
    // console.log("key : ", item);
        setInfoDesk({...infoDesk,
        startDate:item.startDate,
        endDate:item.endDate,
        category:item.category
        });
        setFlashNews(item.flashNews);
        setFaithMsg(item.faithMsg);
        setScrollMsg(item.scrollMsg);
        const tImage=config.s3.scrollUrl+item.scrollMsg.image;
        setProfileImage(tImage);
        setFormType("View");
        //setKeyName(item.folderName);
        //setProjectName(item.projectName);
        newToggle();
    }

    const handleProjectEdit=(event, item)=>{
        event.preventDefault();
        console.log("key : ", item);
        setInfoDesk({...infoDesk,
        startDate:item.startDate,
        endDate:item.endDate,
        category:item.category
        });
        setFlashNews(item.flashNews);
        setFaithMsg(item.faithMsg);
        setScrollMsg(item.scrollMsg);
        const tImage=config.s3.scrollUrl+item.scrollMsg.image;
        setProfileImage(tImage);
        setFormType("Edit");
        setSelectedItem(item);
        //setKeyName(item.folderName);
        //setProjectName(item.projectName);
        newToggle();
    }

    const handleProjectDelete =async(event, item)=>{
        event.preventDefault();
        try{
                const msgid=item.msgID;
                //const session = await Auth.currentSession();
                //const access_token=session.idToken.jwtToken;   
                const { idToken } = (await fetchAuthSession()).tokens ?? {};  
                await axios.delete(`${config.api.invokeURL}/dashboard/infodesk/${msgid}`,{
                headers:{
                            Authorization: idToken,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                        //console.log("Response : ", response);
                        enqueueSnackbar(item.msgID+ "  - Successfully Deleted",{variant:'success'}); 
                    fetchActiveInfo();
                    setLoaded(true);            
                    }).catch((error)=>{
                        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                        setLoaded(true);
                    })
        }
        catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    }
    }

    const postOnS3 = async(fileName)=>{
        try{   
                //const fileName=newFileName
            //  const fileName="infoDesk_"+new Date().getTime();
            // const newFileName=newDoctorDept+"$"+newDoctorName+".jpeg";
            const tnewFileName=fileName+".jpeg";
            const keyName='images/infoDesk';
        
            const bucketName=config.s3.bucketName+keyName;
           // console.log("bucket name : ", bucketName);
           // console.log("file name : ", imageFile);
        
            var tfile = imageFile[0];
            var blob = tfile.slice(0, tfile.size, 'image/jpeg'); 
            const newFile = new File([blob], tnewFileName, {type: 'image/jpeg'});
            //console.log("New file : ", newFile);
            //const session = await Auth.currentSession();
            //const access_token=session.idToken.jwtToken;
            //console.log("access token : ", access_token);
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            await axios.get(`${config.api.invokeURL}/dashboard/images/presigned?fileName=${tnewFileName}&bucketName=${bucketName}`,{
                headers:{
                    Authorization: idToken,
                    'x-api-key': config.api.key
                }       }).then(async(response)=>{
         //   console.log("Response : ", response);
            const res=await fetch(response.data.uploadURL, { method: 'PUT',
            headers:{
                "Content-Type":"image/jpeg"
                },body: newFile});
                //console.log("Res : ", res);
                if(res.status===200){
                //  fetchGalleryImages();
                    setProfileImage(tnewFileName);
                    //var timestamp = new Date().getTime();     
                    // var tprofile = document.getElementById("profile");
                    //var imgsrc="";
                    //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                    //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                    // tprofile.src=imgsrc;
                    //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                    //console.log("tprofle : ", tprofile.src);
                //  setLoaded(true);
                //  newToggle();
                    // fetchGalleryImages();


                }
            }).catch((error)=>{
            //    console.log("error1:", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                setLoaded(true);
        })
        
        }catch(error){
       // console.log("error1:", error);
       enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
        }

    }

/*const editOnS3 = async()=>{
    try{
        if(newProjectDate===""||newProjectName===""){
           // console.log("inputs",newProjectDate, newProjectName, newProjectSize, newProjectStatus, imageFile);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Enter all project details along with an image"
            }); 
            window.scrollTo(0,0); 
        }else{         
        setLoaded(false);
        //const fileName=newProjectName+"_"+new Date().getTime();
      //const newFileName=fileName+".jpeg";
      const newKeyName=newProjectName+"$"+newProjectDate;
      const tKeyName="images/gallery/"+newKeyName;
      const bucketName=config.s3.bucketName+tKeyName;
      //console.log("bucket name : ", bucketName);
      //var file = imageFile;
      //var blob = file.slice(0, file.size, 'image/jpeg'); 
      //const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      //console.log("New file : ", newFile);
      const inputParams={
        fileName:selectedProject,
        newKeyName:newKeyName
    }
   // const session = await Auth.currentSession();
   // const access_token=session.idToken.jwtToken;       
    await axios.post(`${config.api.invokeURL}/dashboard/images/gallery/editgallery`, inputParams,{
    //  await axios.get(`${config.api.invokeUrl}/images/projects/newproject?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
           headers:{
    //           Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
        //   console.log("Response : ", response);
        setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: newProjectName+ "  - Successfully Modified"
        }); 
        setLoaded(true);
        window.scrollTo(0,0); 
        editToggle();
        //fetchGalleryImages();

        /*  const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
             console.log("Res : ", res);
             if(res.status===200){
                fetchGalleryImages();
                 setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
                 newToggle();
                 fetchGalleryImages();
             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            window.scrollTo(0,0);   
         // setDispMsg(error.message);
         // hideLoader();
       })
    }
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
    window.scrollTo(0,0);   
    }

  }
*/
    const handleProjectAdd=async(event)=>{
        event.preventDefault();
        setFormType('Add')
        newToggle();
    }

    const EditMessage=async(event)=>{
        event.preventDefault();
        try{
            if(infoDesk.startDate===""||infoDesk.endDate===""){
                enqueueSnackbar("Start and End Date must be filled with values",{variant:'info'}); 
            }else if(infoDesk.startDate>infoDesk.endDate){
                enqueueSnackbar("Start Date must be less than the End Date",{variant:'info'}); 
            }else {
                const msgid=selectedItem.msgID;
                var tscrollMsg=scrollMsg;
                if(imageFile===""){
                   /* setScrollMsg({...scrollMsg,
                        image:"vjdepth-logo.png"});*/
                    tscrollMsg.image="vjdepth-logo.png";
                }else{
                    /*await setScrollMsg({...scrollMsg,
                    image:msgid+".jpeg"});*/
                    tscrollMsg.image=msgid+".jpeg";
                    await postOnS3(msgid);
                }    
 
                const inputParams={
                    clientID:"VJCollege",
                    msgID:msgid,
                    startDate:infoDesk.startDate,
                    endDate:infoDesk.endDate,
                    msgCategory:infoDesk.msgCategory,
                    msgStatus:infoDesk.msgStatus,
                    flashNews:flashNews,
                    scrollMsg:tscrollMsg,
                    faithMsg:faithMsg
                };
                //console.log("params : ", inputParams);
             //const session = await Auth.currentSession();
             //const access_token=session.idToken.jwtToken; 
             const { idToken } = (await fetchAuthSession()).tokens ?? {}; 
                await axios.patch(`${config.api.invokeURL}/dashboard/infodesk/${msgid}`, inputParams,{
                headers:{
                            Authorization: idToken,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                       // console.log("Response : ", response);
                        enqueueSnackbar("Successfully Updated",{variant:'success'}); 
                    newToggle();
                    fetchActiveInfo();
                    setLoaded(true);
                    }).catch((error)=>{
                        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                        setLoaded(true);
                    // setDispMsg(error.message);
                    // hideLoader();
                    })
        }
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
    }
    }

    const AddNewMessage=async()=>{
        try{
            if(infoDesk.startDate===""||infoDesk.endDate===""){
                enqueueSnackbar("Start and End Date must be filled with values",{variant:'info'}); 
            }else if(infoDesk.startDate>infoDesk.endDate){
                enqueueSnackbar("Start Date must be less than the End Date",{variant:'info'}); 
            }else {
                setLoaded(false);
                const msgid=new Date().getTime().toString();
                //console.log("imageFile : ", imageFile);
                var tscrollMsg=scrollMsg;
                if(imageFile===""){
                    tscrollMsg.image="vjdepth-logo.png";
                }else{
                   // console.log("Else part");
                   tscrollMsg.image=msgid+".jpeg";
                    await postOnS3(msgid);
                }
                const inputParams={
                    clientID:"VJCollege",
                    msgID:msgid,
                    startDate:infoDesk.startDate,
                    endDate:infoDesk.endDate,
                    msgCategory:infoDesk.msgCategory,
                    msgStatus:infoDesk.msgStatus,
                    flashNews:flashNews,
                    scrollMsg:tscrollMsg,
                    faithMsg:faithMsg
                };
                // console.log("params : ", inputParams);
                //const session = await Auth.currentSession();
                //const access_token=session.idToken.jwtToken;
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                await axios.post(`${config.api.invokeURL}/dashboard/infodesk/${msgid}`, inputParams,{
                    headers:{
                            Authorization: idToken,
                            'x-api-key': config.api.key
                        }
                    }).then(async(response)=>{
                        //console.log("Response : ", response);
                        enqueueSnackbar(msgid+ "  - Successfully Added",{variant:'success'}); 
                        newToggle();
                        fetchActiveInfo();
                        setLoaded(true);
                        }).catch((error)=>{
                            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
                        setLoaded(true);
                    })
        }
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
            setLoaded(true);
        }
    }

  return (
    <div>
 <Helmet>
      <title>Flash News - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Flash News - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Flash News - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/infodesk" />
    </Helmet>
   
        <Preloader show={loaded ? false : true} />
         <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      News Section
    </Breadcrumb.Item>
  </Breadcrumb>
        <h3>Active Post</h3>

            <Modal onHide={newToggle} show={!newCollapsed} style={{top:'10%'}}>
            <Modal.Header toggle={newToggle}>
            <Modal.Title>News Section - {formType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InfoDeskForm infoDesk={infoDesk} setInfoDesk={setInfoDesk} flashNews={flashNews} 
                setFlashNews={setFlashNews} scrollMsg={scrollMsg} setScrollMsg={setScrollMsg}
                faithMsg={faithMsg} setFaithMsg={setFaithMsg} formType={formType} 
                setImageFile={setImageFile} profileImage={profileImage} setProfileImage={setProfileImage} 
                />
                    </Modal.Body>
            <Modal.Footer> 
      {formType==="Add"?<Button
        color="primary"
        onClick={AddNewMessage}
      >
        Add Message       
        </Button>:formType==="Edit"?<Button
        color="primary"
        onClick={EditMessage}
      >
        Edit Message       
        </Button>:null}
  {' '}
      <Button onClick={newToggle}>
        Close
      </Button>
    </Modal.Footer>
            </Modal>    

        <Dropdown>
            <Dropdown.Toggle as={Button} onClick={(event)=>{handleProjectAdd(event)}} className="buttonStyle me-2">
            <i className="fa fa-plus me-2" aria-hidden="true"></i>
           {/*} <FontAwesomeIcon icon={faPlus} className="me-2" />*/}
            <span>New</span>
          </Dropdown.Toggle>
  </Dropdown>

       <Table striped bordered hover>
             <thead className='theadStyle'>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Message ID
                </th>
                <th>
                    Start Date
                </th>
                <th>
                    End Date
                </th>
                <th>
                    Category
                </th>
                <th>
                    Action
                </th>
                
                </tr>
            </thead>
            <tbody className='tbodyStyle'>
            {activePost.map((item, index)=>

            <tr key={index} className='trStyle'>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.msgID}
            </td>
            <td>
                {item.startDate}
            </td>
            <td>
                {item.endDate}
            </td>
            <td>
                {item.msgCategory}
            </td>
            
            <td>
            <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
              <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>

               {/*} <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />*/}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={(event)=>{handleProjectView(event, item)}}>
                {/*<FontAwesomeIcon icon={faEye} className="me-2" />*/} 
                <i className="fa fa-eye me-2" aria-hidden="true">View</i>

              </Dropdown.Item>
                <Dropdown.Item onClick={(event)=>{handleProjectEdit(event, item)}}>
               {/*} <FontAwesomeIcon icon={faEdit} className="me-2" />*/}
               <i className="fa fa-pencil me-2" aria-hidden="true">  Edit</i>

              </Dropdown.Item>
              <Dropdown.Item onClick={(event)=>{handleProjectDelete(event, item)}}>
              <i className="fa fa-trash-o me-2" aria-hidden="true"> Delete</i>

                {/*<FontAwesomeIcon icon={faTrash} className="me-2" />*/} 
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
            {/*<FontAwesomeIcon icon={faEye} className="me-2" onClick={(event)=>{handleProjectView(event, item)}}/>
            <FontAwesomeIcon icon={faEdit} className="me-2" onClick={(event)=>{handleProjectEdit(event, item)}}/>
            <FontAwesomeIcon icon={faTrash} className="me-2" onClick={(event)=>{handleProjectDelete(event, item)}}/>

            {/*<button onClick={(event)=>{handleProjectView(event, item)}}>View</button>
            <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
            <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
            </td>
            </tr>)}
            </tbody>
            </Table>

    </div>
  )
}
