import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css'
import './assets/css/grid.css'
import './assets/css/theme.css'
import './assets/css/index.css'

import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux'
import rootReducer from './redux/reducers'
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import { ProductProvider } from './ProductProvider';
import {Amplify} from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import config from './config';
import HomePage from './HomePage';
import {HelmetProvider} from 'react-helmet-async';

const store = createStore(
  rootReducer
)
 
Amplify.configure ({
  Auth: {
    Cognito: {
      mandatorySignId: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolClientId: config.cognito.APP_CLIENT_ID
    }
  }
});

document.title = 'Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
      <HelmetProvider>
        <ProductProvider>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical:'top', horizontal:'right' }}>
            <HomePage />
          </SnackbarProvider>
        </ProductProvider>
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
