import React, { useEffect, useContext, useState } from "react";
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { ProductContext } from '../../ProductProvider';
import Preloader from '../../components/Preloader/Preloader';

export default function LogoutPage() {
    const navigate=useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { loggedUser, setLoggedUser } = useContext(ProductContext);
    const [loaded, setLoaded]= useState(false);

    useEffect(()=>{
        setLoaded(false);
        if(loggedUser.isAuthenticated){
            handleSignOut();
        }else{
            navigate("/login")
        }
        setLoaded(true);
    },[loggedUser])

    const handleSignOut=async()=>{
        //console.log("inside sighout");
        try{
            await signOut()
            .then((res)=>{
            setLoggedUser({...loggedUser,
              name:'',
              email:'',
             // currentStatus:'',
              DOB:'',
              profile:'',
              provinceName:'',
              website:'',
              isAuthenticated:false
            }); 
            navigate("/login");    
          }).catch((error)=>{
            navigate("/");
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
          })
        }catch(error){
            navigate("/");
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
      }
  return (
    <div>
        <Preloader show={loaded ? false : true} />
        Processing Logout ....</div>
  )
}
