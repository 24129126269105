import React, { useEffect, useState } from "react";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
//import { Col, Row, Button, Dropdown } from '@themesberg/react-bootstrap';
//import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
//import { GeneralInfoForm } from "../components/Forms";
import { Col, Row, Card, Form } from 'react-bootstrap';
//import AddImage from "../../components/manageimage/AddImge";

//import Profile3 from "../assets/img/team/profile-picture-3.jpg";


export default function InfoDeskForm(props) {
    const [isDisabled, setIsDisabled]=useState(true);
    const {infoDesk, setInfoDesk, flashNews, setFlashNews, formType } = props;

    useEffect(()=>{
        if(formType==="View"){
        setIsDisabled(true);
        }else{
            setIsDisabled(false);
        }
    },[])

    return (
    <>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">New Display Information</h5>
        <Form>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" value={infoDesk.startDate} 
                onChange={(event)=>{setInfoDesk({...infoDesk,
                startDate:event.target.value})}} 
                disabled={isDisabled}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" value={infoDesk.endDate} 
                onChange={(event)=>{setInfoDesk({...infoDesk,
                    endDate:event.target.value})}}
                    disabled={isDisabled} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="category">
                <Form.Label>Category</Form.Label>
                <Form.Select value={infoDesk.msgCategory} 
                onChange={(event)=>{setInfoDesk({...infoDesk,
                    msgCategory:event.target.value})}}
                    disabled={isDisabled} >
                  <option value="Flash News">Flash News</option>
                  </Form.Select>
              </Form.Group>
            </Col>
            {/*<Col md={6} className="mb-3">
              <Form.Group id="occupation">
                <Form.Label>Occupation</Form.Label>
                <Form.Control required type="text" placeholder="Your Occupation" />
              </Form.Group>
  </Col>*/}
          </Row>
          {infoDesk.msgCategory==="Flash News"?<div>
          <Row>
           {/*} <Col md={6} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>Flash News</Form.Label>
                <Input type="text" />
              </Form.Group>
</Col>*/}
              <Form.Group id="flashNews">
                <Form.Label>Flash News</Form.Label>
                <Form.Control required as="textarea" rows="3" placeholder="Enter the Flash News" 
                value={flashNews} onChange={(event)=>{setFlashNews(event.target.value)}} 
                disabled={isDisabled}/>
              </Form.Group>
          </Row></div>:null}
          {/*infoDesk.category==="Scroll Message"?<div>
          <Row>
            <AddImage formType={formType} setImageFile={setImageFile} profileImage={profileImage}/>
          </Row>

            <Row>
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Control required type="text" placeholder="Title" 
                value={scrollMsg.title} onChange={(event)=>{setScrollMsg({...scrollMsg,
                    title:event.target.value})}} 
                    disabled={isDisabled}
                />
              </Form.Group>
              </Row>
              <Row>
              <Form.Group id="msg">
                <Form.Label>Message</Form.Label>
                <Form.Control required type="textarea" placeholder="Enter the Message" rows="3"
                value={scrollMsg.message} onChange={(event)=>{setScrollMsg({...scrollMsg,
                    message:event.target.value})}} 
                    disabled={isDisabled}/>
              </Form.Group>            
          </Row>
          <Row>
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" placeholder="name@company.com" 
                value={scrollMsg.email} onChange={(event)=>{setScrollMsg({...scrollMsg,
                    email:event.target.value})}}
                    disabled={isDisabled} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group id="Link">
                <Form.Label>Link</Form.Label>
                <Form.Control required type="text" placeholder="https://example.com" 
                value={scrollMsg.link} onChange={(event)=>{setScrollMsg({...scrollMsg,
                    link:event.target.value})}} 
                    disabled={isDisabled}/>
              </Form.Group>
          </Row></div>:null*/}
          
          {/*infoDesk.category==="Faith Message"?<div>
          <Row>
              <Form.Group id="msgHead">
                <Form.Label>Message Header</Form.Label>
                <Form.Control required type="text" placeholder="Enter Message Header" 
                value={faithMsg.head} onChange={(event)=>{setFaithMsg({...faithMsg,
                    head:event.target.value})}}
                    disabled={isDisabled} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group id="msgTitle">
                <Form.Label>Message Title</Form.Label>
                <Form.Control required type="text" placeholder="Enter Message Title" 
                 value={faithMsg.title} onChange={(event)=>{setFaithMsg({...faithMsg,
                    title:event.target.value})}} maxLength={100}
                    disabled={isDisabled}/>
              </Form.Group>
          </Row>
          <Row>
              <Form.Group id="msgText">
                <Form.Label>Message Details</Form.Label>
                <Form.Control required type="textarea" placeholder="Message"  rows="3" 
                value={faithMsg.message} onChange={(event)=>{setFaithMsg({...faithMsg,
                    message:event.target.value})}} maxLength={1000}
                    disabled={isDisabled}/>
              </Form.Group>
          </Row></div>:null*/}
          
        </Form>
      </Card.Body>
    </Card>
    </>
  );
};
