import React, {useContext, useState, useEffect} from 'react';
import './topnav.css';
import { Link } from 'react-router-dom';
import Dropdown from '../dropdown/Dropdown';
import ThemeMenu from '../thememenu/ThemeMenu';
//import notifications from '../../assets/JsonData/notification.json';
//import user_image from '../../assets/images/TathagataImage.jpg';
import user_menu from '../../assets/JsonData/user_menus.json';
import { ProductContext } from "../../ProductProvider";
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';

/*const curr_user = {
    display_name: 'Tathagata Chakraborty',
    image: myProfileImage
}*/

/*const renderNotificationItem = (item, index) => (
    <div className="notification-item" key={index}>
        <i className={item.icon}></i>
        <span>{item.content}</span>
    </div>
)*/

const renderUserToggle = (user) => (
    <div className="topnav__right-user">
        <div className="topnav__right-user__image">
            <img src={user.image} alt="" />
        </div>
        {/*<div className="topnav__right-user__name">
            {user.display_name}
</div>*/}
    </div>
)

const renderUserMenu =(item, index) => (
    <Link to={item.linkTo} key={index}>
        <div className="notification-item">
            <i className={item.icon}></i>
            <span>{item.content}</span>
        </div>
    </Link>
)

const Topnav = () => {
    const { loggedUser, myProfileImage} = useContext(ProductContext);

    const [curr_user, setCurrUser]= useState({display_name:'Guest',image:''});

    useEffect(() => {
      setCurrUser({...curr_user,
        display_name: loggedUser.name,
        image: myProfileImage
    })    
    }, [loggedUser])
    
    return (
        <div className='topnav'>
           {/*} <div className="topnav__search">
                <input type="text" placeholder='Search here...' />
                <i className='bx bx-search'></i>
            </div>
            <img src={logo} style={{width:"30px", height:"30px", borderRadius:'50%'}} />*/}
             <h4>Vidyajyoti College</h4>
            <div className="topnav__right">
                {/*<div className="topnav__right-item">
                    <Dropdown
                        icon='bx bx-bell'
                        badge='12'
                        contentData={notifications}
                        renderItems={(item, index) => renderNotificationItem(item, index)}
                        renderFooter={() => <Link to='/'>View All</Link>}
                    />
                </div>*/}
                <div className="topnav__right-item" >
                    <ThemeMenu />
                </div>
                <div className="topnav__right-item">
                {/* dropdown here */}
                    <Dropdown
                        customToggle={() => renderUserToggle(curr_user)}
                        contentData={user_menu}
                        renderItems={(item, index) => renderUserMenu(item, index)}
                    />
                </div>
            </div>
        </div>
    )
}

export default Topnav
