import React, {useEffect, useState, useContext} from 'react'
import { Route, Routes } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ThemeAction from './redux/actions/ThemeAction'
import Topnav from './components/topnav/TopNav';
import LoginPage from './pages/auth/LoginPage';
import ForgotPassword from './pages/auth/ForgotPassword';
import {ProductContext} from './ProductProvider';
import LandingPage from './pages/LandingPage';
import ChangePassword from './pages/auth/ChangePassword';
import Sidebar from './components/prosidebar/Sidebar';
import LogoutPage from './pages/auth/LogoutPage';
import './App.css';
//import MarkSheetView from './pages/student/MarkSheetView';
import CourseView from './pages/student/CourseView';
import MyProfile from './pages/student/MyProfile';
import MarksMainView from './pages/marksheet/MarksMainView';
import SliderViewer from './pages/website/SliderViewer';
import GalleryViewer from './pages/website/GalleryViewer';
import ActiveInfo from './pages/website/infoDesk/ActiveInfo';

function HomePage() {
  const themeReducer = useSelector(state => state.ThemeReducer)
  //const navigate = useNavigate();
  const dispatch = useDispatch()
  //const [isSidebar, setIsSidebar] = useState(true);
  const { loggedUser, sidebarStatus, setSidebarStatus} = useContext(ProductContext);
  const [collapsed, setCollapsed] = useState(true);
  const [image, setImage] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    //console.log("clicked")
    setCollapsed(!collapsed);
  };

  const handleCollapsedLeftChange = (event) => {
    // console.log("clicked");
    event.preventDefault();
     //console.log("collapsed :", collapsed);
     //console.log("sidebar : ", sidebarStatus);
     setCollapsed(true);
     setSidebarStatus("Shrunk");
   };

   const handleCollapsedRightChange = (event) => {
    // console.log("clicked");
    event.preventDefault();
    // console.log("collapsed :", collapsed);
     //console.log("sidebar : ", sidebarStatus);
     setCollapsed(false);
     setSidebarStatus("Orginal");
   };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  useEffect(() => {
    const themeClass = localStorage.getItem('themeMode', 'theme-mode-light')
    const colorClass = localStorage.getItem('colorMode', 'theme-mode-light')
    dispatch(ThemeAction.setMode(themeClass))
    dispatch(ThemeAction.setColor(colorClass))
    //setSidebarStatus("Orginal");
    {/*if(loggedUser.isAuthenticated){
     navigate("/");
    }else{
      navigate("/login");
    }*/}
    //console.log("side bar status :", sidebarStatus);
}, [dispatch, loggedUser, sidebarStatus])

  return (
    <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
      <div className="app">
        {loggedUser.isAuthenticated && <Sidebar image={image}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        handleCollapsedLeftChange={handleCollapsedLeftChange} 
        handleCollapsedRightChange={handleCollapsedRightChange}
       />}
        <main className={sidebarStatus==="Full"?"layout__content-full":sidebarStatus==="Orginal"?"layout__content-main":sidebarStatus==="Shrunk"?"layout__content":"layout__content"}>
        {loggedUser.isAuthenticated && <Topnav />}
        <div className="">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/marksheet" element={<MarksMainView />} />
            <Route path="/course" element={<CourseView />} />
            <Route path="/profile" element={<MyProfile />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/logout" element={<LogoutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/slider-viewer" element={<SliderViewer />} />
            <Route path="/gallery-viewer" element={<GalleryViewer />} />
            <Route path="/infodesk" element={<ActiveInfo />} />

          </Routes>
          </div>
        </main>
        </div>
        {/*}<div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
                   <ProSidebar />
                    <div className="layout__content">
                        <Topnav/>
                        <div className="layout__content-main">
                            <NavRoutes/>
                        </div>
                    </div>
            </div>*/}
    </div>
  );
}

export default HomePage;
