import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import { useSnackbar } from 'notistack';
import {Table, Button, Modal, Breadcrumb} from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEye, faTrash, faEdit, faHome } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet-async';
import { Link } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
import AddGallery from './AddGallery';
import EditGallery from './EditGallery';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
import GalleryImageLoader from './GalleryImageLoader';
import Preloader from '../../components/Preloader/Preloader';
import '../../styles.css';
import 'bootstrap/dist/css/bootstrap.css'; 

const config = require('../../config.json');

export default function GalleryViewer() {
    const [loaded, setLoaded] = useState(false);
    const { validateLogin, loggedUser} = useContext(ProductContext);
    const [galleryImages, setGalleryImages]=useState([]);
    const [viewImage, setViewImage] = useState(false);
    const [addImage, setAddImage] = useState(false);
    const [editImage, setEditImage]=useState(false);
    const [displayImages, setDisplayImages] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [newCollapsed, setNewCollapsed] = useState(true);
    const [editCollapsed, setEditCollapsed] = useState(true);
    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState(logo);
    const [keyName, setKeyName]=useState('');
    const [projectName, setProjectName]=useState('');
    const [projectDate, setProjectDate]=useState('');
    const [newProjectName, setNewProjectName]=useState('');
    const [newProjectSize, setNewProjectSize]=useState('');
    const [newProjectStatus, setNewProjectStatus]=useState('');
    const [newProjectDate, setNewProjectDate]=useState('');
    const [selectedProject, setSelectedProject]=useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
      if(loggedUser.isAuthenticated && loggedUser.profile==="Super Student"){
        fetchGalleryImages();
      }else{
        validateLogin();
      }
    }, [loggedUser])  
  
    const toggleDrop = () => setDropdownOpen((prevState) => !prevState);
      
  const newToggle = () => {
   setNewCollapsed(!newCollapsed);
  }
  
  const editToggle = () => {
   //console.log("clicked");
       setEditCollapsed(!editCollapsed);
   }
  
  const handleProjectEdit=(event, id)=>{
  event.preventDefault();
  const itemtest=galleryImages.filter((image)=>image.id===id);
  const item=itemtest[0];
  //
  // console.log("Item : ", item);
  setKeyName(item.folderName);
  setProjectName(item.projectName);
  setProjectDate(item.projectDate);
  setNewProjectName(item.projectName);
  setNewProjectStatus(item.projectStatus);
  setNewProjectSize(item.projectSize);
  setNewProjectDate(item.projectDate);
  setSelectedProject(item);
  handleOpenImageEdit();
  
  
  }
  
  const handleProjectDelete =async(event, id)=>{
  event.preventDefault();
  const itemtest=galleryImages.filter((image)=>image.id===id);
  const item=itemtest[0];
  
  //console.log("Item : ", item);
  try{
    //const bucketName=config.s3.bucketName;
    const inputParams={
        fileName:item
    }
    //const session = await Auth.currentSession();
    //const access_token=session.idToken.jwtToken;
    const { idToken } = (await fetchAuthSession()).tokens ?? {};

    await axios.post(`${config.api.invokeURL}/gallery/delgallery`, inputParams,{
  //await axios.get(`${config.api.invokeUrl}/images/projects/delproject?fileName=${item}&bucketName=${bucketName}`,{
    headers:{
        Authorization: idToken,
        'x-api-key': config.api.key
    }
  }).then(async(response)=>{
    //console.log("Response : ", response);
    fetchGalleryImages();
    enqueueSnackbar("Project & Images successfully removed",{variant : 'success'});
    window.scrollTo(0,0);   
  }).catch((error)=>{
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  });
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  }
  
  }
  
  const postOnS3 = async()=>{
  try{
    
    if(newProjectDate===""||newProjectName===""||imageFile===""){
       // console.log("inputs",newProjectDate, newProjectName, newProjectSize, newProjectStatus, imageFile);
       enqueueSnackbar("Enter all details along with an image",{variant : 'info'});
        window.scrollTo(0,0); 
    }else{        
  setLoaded(false);
  const fileName=newProjectName+"_"+new Date().getTime();
  const newFileName=fileName+".jpeg";
  const newKeyName=newProjectName+"$"+newProjectDate;
  const tKeyName="/images/gallery/"+newKeyName;
  const bucketName=config.s3.bucketName+tKeyName;
  //console.log("bucket name : ", bucketName);
  var file = imageFile;
  var blob = file.slice(0, file.size, 'image/jpeg'); 
  const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
  console.log("New file : ", newKeyName);
  //const session = await Auth.currentSession();
  //const access_token=session.idToken.jwtToken; 
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
  await axios.get(`${config.api.invokeURL}/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
       headers:{
           Authorization: idToken,
           'x-api-key': config.api.key
       }
   }).then(async(response)=>{
       //console.log("Response : ", response);
       enqueueSnackbar("Image Uploaded ",{variant : 'success'});
       handleCloseImageAdd();
       fetchGalleryImages();
       setLoaded(true);
      const res=await fetch(response.data.uploadURL, { method: 'PUT',
       headers:{
           "Content-Type":"image/jpeg"
         },body: newFile});
         //console.log("Res : ", res);
         if(res.status===200){
          //  fetchGalleryImages();
  
             setProfileImage(newFileName);
             //var timestamp = new Date().getTime();     
            // var tprofile = document.getElementById("profile");
             //var imgsrc="";
             //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
             //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
            // tprofile.src=imgsrc;
            //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
             //console.log("tprofle : ", tprofile.src);
             newToggle();
         }
    }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        window.scrollTo(0,0);   
   })
  }
  }catch(error){
  enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  setLoaded(true);
  window.scrollTo(0,0);   
  }
  
  }
  
  const editOnS3 = async()=>{
  try{
  //console.log("newProject name : ", newProjectName, newProjectDate);
    if(newProjectDate===""||newProjectName===""){
       // console.log("inputs",newProjectDate, newProjectName, newProjectSize, newProjectStatus, imageFile);
       enqueueSnackbar("Enter all details along with an image",{variant : 'info'});
        window.scrollTo(0,0); 
        editToggle();
    }else if(newProjectDate===projectDate && newProjectName===projectName){
      // console.log("inputs",newProjectDate, newProjectName, newProjectSize, newProjectStatus, imageFile);
      enqueueSnackbar("No Changes made to the Gallery",{variant : 'info'});
       window.scrollTo(0,0); 
       editToggle();
      }else{         
    setLoaded(false);
    //const fileName=newProjectName+"_"+new Date().getTime();
  //const newFileName=fileName+".jpeg";
  const newKeyName=newProjectName+"$"+newProjectDate;
  const tKeyName="/images/gallery/"+newKeyName;
  //const bucketName=config.s3.bucketName+tKeyName;
  
  //console.log("bucket name : ", bucketName);
  //var file = imageFile;
  //var blob = file.slice(0, file.size, 'image/jpeg'); 
  //const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
  //console.log("New file : ", newFile);
  const inputParams={
    fileName:selectedProject,
    newKeyName:newKeyName
  }
  //console.log("Params : ", inputParams);
  //const session = await Auth.currentSession();
  //const access_token=session.idToken.jwtToken;  
  const { idToken } = (await fetchAuthSession()).tokens ?? {};
     
  await axios.post(`${config.api.invokeURL}/gallery/editgallery`, inputParams,{
  //  await axios.get(`${config.api.invokeUrl}/images/projects/newproject?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
       headers:{
           Authorization: idToken,
           'x-api-key': config.api.key
       }
   }).then(async(response)=>{
    //   console.log("Response : ", response);
    enqueueSnackbar("Successfully Edited",{variant : 'success'});
    setLoaded(true);
    window.scrollTo(0,0); 
    handleCloseImageEdit();
    fetchGalleryImages();
  
    /*  const res=await fetch(response.data.uploadURL, { method: 'PUT',
       headers:{
           "Content-Type":"image/jpeg"
         },body: newFile});
         console.log("Res : ", res);
         if(res.status===200){
            fetchGalleryImages();
             setProfileImage(newFileName);
             //var timestamp = new Date().getTime();     
            // var tprofile = document.getElementById("profile");
             //var imgsrc="";
             //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
             //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
            // tprofile.src=imgsrc;
            //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
             //console.log("tprofle : ", tprofile.src);
             newToggle();
             fetchGalleryImages();
         }*/
    }).catch((error)=>{
        //console.log("error1:", error);
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        window.scrollTo(0,0);   
     // setDispMsg(error.message);
     // hideLoader();
   })
  }
  }catch(error){
  //console.log("error1:", error);
  enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
  setLoaded(true);
  window.scrollTo(0,0);   
  }
  
  }
  
  const handleOpenImageView = () => setViewImage(true);
  const handleCloseImageView = () => setViewImage(false);
  const handleOpenImageAdd = () => setAddImage(true);
  const handleCloseImageAdd = () => setAddImage(false);
  const handleOpenImageEdit = () => setEditImage(true);
  const handleCloseImageEdit = () => setEditImage(false);
  
  const fetchGalleryImages =async()=>{
    try{
        setLoaded(false);
        //console.log("Testing");
        //const session = await Auth.currentSession();
        //const access_token=session.idToken.jwtToken;
        const fileName='images/gallery/';
        const bucketName="images.vidyajyoticollege.in";
    await axios.get(`${config.api.invokeURL}/gallery?fileName=${fileName}&bucketName=${bucketName}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        //console.log(response.data);
        processGalleryData(response.data);
        //setGalleryImages(response.data);
        enqueueSnackbar(response.data.length + " - Project Images are available",{variant:'success'});
        //setLoaded(true);
        //setDispMsg(response.data.length + " - Images are found in your Gallery");
    }).catch((error)=>{
        //console.log(error.message);
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        window.scrollTo(0,0);   
        //setDispMsg(error.message);
    })
  }catch(error){
  //console.log(error.message);
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
    setLoaded(true);
    window.scrollTo(0,0);   
    //setDispMsg(error.message);
  }
  }

  const processGalleryData=(item)=>{
    const test=[];
    for(const i in item){
        var testv={};
        testv=item[i];
        testv.length=item[i].fileName.length;
        testv.id=parseInt(i)+1;
        test.push(testv);
        testv={};
    }
    //console.log("testdata :", test);
    setGalleryImages(test);
    setLoaded(true);
  }

  const handleProjectView=async(e, id)=>{
    e.preventDefault();
    const itemtest=galleryImages.filter((image)=>image.id===id);
    const item=itemtest[0];
    //console.log("key : ", item);
    setKeyName(item.folderName);
    setProjectName(item.projectName);
    setProjectDate(item.projectDate);
    setNewProjectName(item.projectName);
    setNewProjectStatus(item.projectStatus);
    setNewProjectSize(item.projectSize);
    setNewProjectDate(item.projectDate);
    setSelectedProject(item);

    const tfoldername=item.folderName.substring(0, item.folderName.length-1);
    //console.log("TFoldername : ", tfoldername);
    try{
        setLoaded(false);
        const fileName=tfoldername;
        const bucketName="images.vidyajyoticollege.in";
        await axios.get(`${config.api.invokeURL}/gallery/slider?fileName=${fileName}&bucketName=${bucketName}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
        ).then((response)=>{
        const finalList=[];
        //console.log("res:", response);
        if(response.data.length>0){
          for (const i in response.data){
            const tValue=response.data[i];
          //  console.log("tvalue:", tValue);
            const tPathName=tValue.split("/");
            //console.log("tpathname:", tPathName);
            const tBreakUp=tPathName[2];
            //console.log("tbreakup:", tBreakUp);
            const tempValue={};
            tempValue.fullPathName=response.data[i];
            tempValue.fileName=tBreakUp;
            finalList.push(tempValue);
          }
        }
        setDisplayImages(finalList);
        handleOpenImageView();
        enqueueSnackbar(response.data.length + " - Images are found in your " + item.projectName + " section",{variant : 'success'});
        setLoaded(true);
        window.scrollTo(0,0); 
    }).catch((error)=>{
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoaded(true);
        window.scrollTo(0,0); 
    })
  }catch(error){
    enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
      setLoaded(true);
      window.scrollTo(0,0); 
  }
  setLoaded(true);
  }
  
  return (
    <div>
    <Preloader show={loaded ? false : true} />
    <Helmet>
      <title>Gallery - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Gallery - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Gallery - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/gallery-viewer" />
    </Helmet>
       <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Gallery
    </Breadcrumb.Item>
    </Breadcrumb>

    {/* View Images */}
    <div>
    <Modal show={viewImage} onHide={handleCloseImageView} size="xl">
    <Modal.Header closeButton>
        <Modal.Title>View Gallery Images</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalStyle'>
          <div style={{width:"100%"}}>
          <GalleryImageLoader keyName={keyName} projectName={projectName} selectedProject={selectedProject}
          galleryImages={displayImages} setGalleryImages={setDisplayImages} orginalfetchGalleryImages={fetchGalleryImages}/>
          </div>
     </Modal.Body>
        <Modal.Footer>
        <div style={{display:"flex", justifyContent:"end"}}>
              <Button type="submit" className='buttonStyle' onClick={event=>handleCloseImageView(event)}>
                Cancel
              </Button>
            </div>
        </Modal.Footer>
    </Modal>
    </div>
    {/* Add Images */}
    <Modal show={addImage} onHide={handleCloseImageAdd} maxWidth={'md'} fullWidth dialogClassName='modalStyle'> 
          <Modal.Header closeButton>
            <Modal.Title>Create New Gallery</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalStyle'>
        <AddGallery setNewProjectName={setNewProjectName} setNewProjectSize={setNewProjectSize} setNewProjectStatus={setNewProjectStatus} setNewProjectDate={setNewProjectDate} setImageFile={setImageFile} profileImage={profileImage} />
      </Modal.Body>
        <Modal.Footer>
        <div style={{display:"flex", justifyContent:"end"}}>
              <Button type="submit" className='buttonStyle' onClick={event=>postOnS3(event) }>
                Save
              </Button>
              <Button className='buttonStyle' onClick={event=>handleCloseImageAdd(event)}>
                Cancel
              </Button>
            </div>
        </Modal.Footer>
    </Modal>
    {/* Edit Images */}
    <Modal show={editImage} onHide={handleCloseImageEdit} maxWidth={'md'} fullWidth>
          <Modal.Header closeButton>
            <Modal.Title>Edit Gallery Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{width:"100%"}}>
          <EditGallery newProjectName={newProjectName} newProjectStatus={newProjectStatus} newProjectSize={newProjectSize} newProjectDate={newProjectDate} setNewProjectName={setNewProjectName} setNewProjectSize={setNewProjectSize} setNewProjectStatus={setNewProjectStatus} setNewProjectDate={setNewProjectDate}/>
          </div>
     </Modal.Body>
        <Modal.Footer>
        <div style={{display:"flex", justifyContent:"end"}}>
        <Button type="submit" className='buttonStyle' onClick={event=>editOnS3(event)}>
                Update
              </Button>
              <Button className='buttonStyle' onClick={event=>handleCloseImageEdit(event)}>
                Cancel
              </Button>
            </div>
        </Modal.Footer>
    </Modal>
    <h3>Gallery Area</h3>

    <div m="20px">
      <div className='container' maxWidth="md" style={{padding:"20px"}}>
        <Button type="submit" className='buttonStyle'
        onClick={handleOpenImageAdd}>
          New
        </Button>
      </div>

      <div className='card table-wrapper'>
       {loaded &&  
       <Table striped bordered hover>
        <thead className='theadStyle'>
          <tr>
              <th>#</th>
              <th>Gallery Name</th>
              <th>Created On</th>
              <th>Images Uploaded</th>
              <th>Actions</th>
          </tr>
        </thead>
        <tbody className='tbodyStyle'>
            {galleryImages.map((item,index)=>(
                <tr className='trStyle' key={index}>
                <td>{item.id}</td>
                    <td>{item.projectName}</td>
                    <td>{item.projectDate}</td>
                    <td>{item.length}</td>
                    <td> <div style={{display: 'flex', flexDirection:'row'}}>                 
                    <div style={{paddingLeft:10}}><i className="fa fa-eye" aria-hidden="true" onClick={event=>handleProjectView(event, item.id)}></i></div>
                    <div style={{paddingLeft:10}}><i className="fa fa-pencil" aria-hidden="true" onClick={event=>handleProjectView(event, item.id)}></i></div>
                    <div style={{paddingLeft:10}}><i className="fa fa-trash-o" aria-hidden="true" onClick={event=>handleProjectView(event, item.id)}></i></div>
                    </div>
                    </td>
                </tr>
            ))}
        </tbody>
        </Table>}
      </div>
    </div>
    </div>
  )
}
