import React, {useState, useContext, useEffect} from 'react';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { Link } from "react-router-dom";
import {Button, Form, Row, Col} from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import { ProductContext } from '../../ProductProvider';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
import Preloader from '../../components/Preloader/Preloader';
import PasswordStrengthIndicator from "../../components/passwordStrengthIndicator";
import {Helmet} from 'react-helmet-async';

//const config = require('../../config.json');

//const phoneRegExp =
  ///^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const isNumberRegx = /\d/;
  const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const capsRegx=/[A-Z ]+/;
  

export default function LoginPage() {
    const { validateLogin, setSidebarStatus, sidebarStatus} = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showNewPass, setShowNewPass] = useState(false);
    const [newPassword, setNewPassword]=useState('');
    const [loaded, setLoaded] = useState(true);
    //const navigate = useNavigate();
    const [passwordValidity, setPasswordValidity] = useState({
      minChar: null,
      number: null,
      specialChar: null,
      capsChar:null
    });
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [newpasswordFocused, setNewPasswordFocused] = useState(false);
    const [newpasswordValidity, setNewPasswordValidity] = useState({
      minChar: null,
      number: null,
      specialChar: null,
      capsChar:null
    });


    useEffect(() => {
        validateLogin();
        setSidebarStatus("Full");
      //console.log("logged user : ", loggedUser);
      //console.log("side bar status :", sidebarStatus);
    }, [])
  
    const handleLogin = async(event) => {
        event.preventDefault();
        try{
            setLoaded(false);
            if(username===""||password===""){
              enqueueSnackbar("Username and Password cannot be empty",{variant:'info'});
              setLoaded(true);
            }else{
         // await Auth.signIn(username, password)
         //const { isSignedIn, nextStep } = await signIn({ username, password });
         //console.log("isSignedIn :",isSignedIn );
         //console.log("nextStep :",nextStep );
         await signIn({ 
          username, 
          password,
         /* options: {
            authFlowType: "USER_PASSWORD_AUTH",
          },*/
          })
         .then(async(user)=>{
          //console.log("User" , user);
          if(!user.isSignedIn && user.nextStep.signInStep==="CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"){
            //setUser(user);
            setPasswordFocused(false);
            setShowNewPass(true);
            enqueueSnackbar("Set up your New Password",{variant:'info'});
          }else{  
            await validateLogin();
         }
         setLoaded(true);
        })
         .catch((error)=>{
          //console.log("Error :", error);
          setLoaded(true);
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
         })
    }
          }catch(error){
            //console.log("Error", error);
            setLoaded(true);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});            
          }
      };

      const handleChangePassword =async(event)=>{
        event.preventDefault();
        try{
          setLoaded(false);
          const attributes = {
            email: username,
           };
           await confirmSignIn({
            challengeResponse: newPassword,
           })
          .then(async(user) => {
            //console.log(user);
            if(user.isSignedIn){
              await validateLogin();
              enqueueSnackbar("Authenticated Successfully!",{variant:'success'});
              setLoaded(true);    
            }
            setSidebarStatus("Orginal");
        }).catch(e => {
          setLoaded(true);
          enqueueSnackbar("Error Occured - " + e.message,{variant : 'warning'});
        });
        }catch(error){
          setLoaded(true);
          enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        }
      }

      const onChangePassword = password => {
        setPassword(password);

        setPasswordValidity({
            minChar: password.length >= 8 ? true : false,
            number: isNumberRegx.test(password) ? true : false,
            specialChar: specialCharacterRegx.test(password) ? true : false,
            capsChar:capsRegx.test(password)?true:false
        });
    };

    const onNewChangePassword = password => {
      setNewPassword(password);

      setNewPasswordValidity({
          minChar: password.length >= 8 ? true : false,
          number: isNumberRegx.test(password) ? true : false,
          specialChar: specialCharacterRegx.test(password) ? true : false,
          capsChar:capsRegx.test(password)?true:false
      });
  };
     
  
  return (
    <>
     <Helmet>
      <title>Login - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Login - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Login - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/login" />
    </Helmet>
   
    <Preloader show={loaded ? false : true} />
    <div style={{marginTop:'5%', marginBottom:'auto'}}>
      <div className="col d-flex justify-content-center text-center">
        <div className="card">
          <div className="card__body">
        <h2>Vidyajyoti College of Theology</h2>
        <h2>Delhi</h2>
        <img
            src={logo}
            alt="logo"
            width="150"
            className="shadow-light rounded-circle" />
          <h4>Student's Login</h4>
          <div component="form">
            <Form>
            <Form.Group  as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label column lg="4">Email address</Form.Label>
            <Col lg="8">
            <Form.Control type="email" placeholder="name@example.com"
                          autoFocus disabled={showNewPass}
                          onChange={event=>setUsername(event.target.value)}
                          value={username} />
            </Col>
          </Form.Group>
          </Form>
            {!showNewPass ?
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column lg="4">
                    Password
                  </Form.Label>
                  <Col lg="8">
                    <Form.Control type="password" placeholder="Password"   
                    onFocus={() => setPasswordFocused(true)}
                    onChange={e =>
                        onChangePassword(e.target.value)}            
              value={password}/>
                  </Col>
                </Form.Group>:
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
            <Form.Label column lg="2">
              New Password
            </Form.Label>
            <Col lg="10">
              <Form.Control type="password" placeholder="Password"   
                onFocus={() => setNewPasswordFocused(true)}
                onChange={e =>
                    onNewChangePassword(e.target.value)}            
              value={newPassword}/>
            </Col>
          </Form.Group>}
          {passwordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={passwordValidity}
                                    />
                                )}
          {newpasswordFocused && (
                                    <PasswordStrengthIndicator
                                        validity={newpasswordValidity}
                                    />
                                )}
          {!showNewPass? <Button
            type="submit"
            variant="primary"
            onClick={event=>{handleLogin(event)}}>
            Sign In
          </Button>:<Button
            type="submit"
            variant="primary"
            onClick={event=>{handleChangePassword(event)}}>
              Change Password
          </Button>}
            <div className='container'>
              <div>
                <Link to="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}
