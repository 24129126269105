import React,{useState, useContext, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import { updatePassword } from 'aws-amplify/auth';
import {Button, Form, Row, Col, Breadcrumb} from 'react-bootstrap';
import { ProductContext } from '../../ProductProvider';
import Preloader from '../../components/Preloader/Preloader';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from 'react-helmet-async';
import { Link } from "react-router-dom";
//import { FaHome } from "react-icons/fa";


export default function ChangePassword() {
    const { loggedUser, validateLogin } = useContext(ProductContext);
    const { enqueueSnackbar } = useSnackbar();
    const [oldPassword, setOldPassword]= useState('');
    const [newPassword, setNewPassword] = useState('');
    const [loaded, setLoaded] = useState(true);

    useEffect(() => {
        if(!loggedUser.isAuthenticated){
          validateLogin();
        }
      }, [loggedUser])

    const handleChangePassword=async(event)=>{
        event.preventDefault();
        try{
            setLoaded(false);
            if(oldPassword===""){
                enqueueSnackbar("Enter your Current Password!",{variant:'info'});
                setLoaded(true);
            }else if(newPassword===""){
                enqueueSnackbar("Enter your New Password!",{variant:'info'});
                setLoaded(true);
            }else{
                await updatePassword({ oldPassword, newPassword });
                enqueueSnackbar("Password Updated Successfully!",{variant:'success'});
                setLoaded(true);
            }
        } catch (err) {
            setLoaded(true);
            enqueueSnackbar("Error Occured - " + err.message,{variant : 'warning'});
        }
    }

  return (
    <>
    <Preloader show={loaded ? false : true} />
    <Helmet>
      <title>Change Password - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Change Password - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Change Password - Student Portal - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
      <link rel="canonical" href="/change-password" />
    </Helmet>
       <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">
      <i className="fa fa-home" aria-hidden="true"></i>
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Profile
    </Breadcrumb.Item>
    <Breadcrumb.Item >
      Change Password
    </Breadcrumb.Item>
  </Breadcrumb>
        <h3>Change Password</h3>


    <div style={{marginTop:'5%', marginBottom:'auto'}}>
    <div className="d-flex justify-content-center text-center">
      <div className="card" style={{width:"80%"}}>
        <div className="card__body">
        <div component="form" noValidate >
          <Form>
          <Form.Group  as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
      <Form.Label column lg="4">Email address</Form.Label>
      <Col lg="8">
      <Form.Control type="email" placeholder="name@example.com"
        autoFocus
        value={loggedUser.email}
        disabled/>
       </Col>
       </Form.Group>
          </Form>
          <div>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                <Form.Label column lg="4">
                  Current Password
                </Form.Label>
                <Col lg="8">
                  <Form.Control type="text" placeholder="Enter current Password"               
              onChange={event=>setOldPassword(event.target.value)}
              value={oldPassword}/>
                </Col>
              </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
          <Form.Label column lg="4">
            New Password
          </Form.Label>
          <Col lg="8">
            <Form.Control type="text" placeholder="Enter New Password"               
              onChange={event=>setNewPassword(event.target.value)}
              value={newPassword}/>
          </Col>
        </Form.Group></div>
          {/*<FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
      />*/}
           <Button
            type="submit"
            fullWidth
            variant="primary"
            onClick={event=>handleChangePassword(event)}>
                Change Password
          </Button>
        </div>
        </div>
      </div>
    </div>
  </div>
  </>
  )
}
