import React,{useContext} from 'react';
import { Link } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from 'react-pro-sidebar';
//import { FaAngleDoubleLeft, FaAngleDoubleRight, FaTachometerAlt, FaBook, FaMarker, FaUserCircle, FaKey, FaSignOutAlt } from 'react-icons/fa';
import logo from '../../assets/images/logo/COLLEGELOGO.jpeg';
import sidebarBg from '../../assets/images/bg1.jpg';
import '../../styles.css';
import '../../../node_modules/react-pro-sidebar/dist/css/styles.css';
import { ProductContext } from "../../ProductProvider";

const Sidebar = ({
  image,
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedLeftChange,
  handleCollapsedRightChange
}) => {
  const { loggedUser, myProfileImage} = useContext(ProductContext);

  return (
    <div className='sidebar'>
    <ProSidebar
      image={image ? sidebarBg : false}
      collapsed={collapsed}
      toggled={true}
      onToggle={handleToggleSidebar}
      breakPoint="md"
    >
      {/* Header */}
      <SidebarHeader>
        <Menu iconShape="circle">
          {collapsed ? (
            <MenuItem style={{zIndex:99}}
              icon={<i className="fa fa-angle-double-right" aria-hidden="true"></i>}
              onClick={handleCollapsedRightChange}
            ></MenuItem>
          ) : (
            <MenuItem style={{zIndex:99}}
              icon={<i className="fa fa-angle-double-left" aria-hidden="true"></i>
            }
              onClick={handleCollapsedLeftChange}
            >
              <div className="col d-flex justify-content-center text-center">
              <img src={logo} style={{width:"60px", height:"60px", borderRadius:'50%'}} />
              </div>
             {/*} <div className="col d-flex justify-content-center text-center"
                style={{
                  padding: '9px',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  fontSize: 15,
                  letterSpacing: '1px'
                }}
              >
                Vidyajyoti College
              </div>*/}
              <div className="col d-flex justify-content-center text-center">
                {loggedUser.name}
                <br />
                ({loggedUser.profile})
              </div>
            </MenuItem>
          )}
        </Menu>
      </SidebarHeader>
      {/* Content */}
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<i className="fa fa-tachometer" aria-hidden="true"></i>}><Link to="/" > Dashboard </Link> </MenuItem>
          <MenuItem icon={<i className="fa fa-book" aria-hidden="true"></i>}><Link to="/course" > Course </Link> </MenuItem>
          <MenuItem icon={<i className="fa fa-bookmark" aria-hidden="true"></i>}><Link to="/marksheet" > Mark Sheet </Link> </MenuItem>
          {loggedUser.profile==="Super Student"?<SubMenu title="Web Site" icon={<i className="fa fa-globe" aria-hidden="true"></i>}>
         <MenuItem prefix={<i className="fa fa-slideshare" aria-hidden="true"></i>}><Link  to="/slider-viewer"> Slider</Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-picture-o" aria-hidden="true"></i>}><Link  to="/gallery-viewer">  Gallery </Link></MenuItem>
         <MenuItem prefix={<i className="fa fa-inbox" aria-hidden="true"></i>}><Link  to="/infodesk">  Infodesk </Link></MenuItem>
        </SubMenu>:null}

          <MenuItem icon={<i className="fa fa-user" aria-hidden="true"></i>}><Link to="/profile" > My Profile </Link> </MenuItem>
          <MenuItem icon={<i className="fa fa-key" aria-hidden="true"></i>}><Link to="/change-password" > Change Password </Link> </MenuItem>
        {/*<SubMenu title="Web Site"
            icon={<FaGlobe />}>
         <MenuItem icon={<FaRegLaughWink />}><Link  to="/slider-viewer"> Slider</Link></MenuItem>
         <MenuItem icon={<FaGlobe />}><Link  to="/gallery-viewer">  Gallery </Link></MenuItem>
         <MenuItem icon={<FaFlask />}><Link  to="/infodesk">  Infodesk </Link></MenuItem>
          </SubMenu>*/}
        </Menu>
      </SidebarContent>
      {/* Footer */}
      <SidebarFooter style={{ textAlign: 'center' }}>
        <div className="sidebar-btn-wrapper" style={{ padding: '16px' }}>
          <Link
            className="sidebar-btn"
            style={{ cursor: 'pointer' }}
            to="/logout"
          >
            <i className="fa fa-sign-out" aria-hidden="true"></i>
            <span>Logout</span>
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
    </div>
  );
};

export default Sidebar;
